<template>
  <div id="contractManagement" v-if="!authPick" :style="{ width: tableWidth + ' !important' }">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <div class="headerContainer">
          <div class="top-header-tit">
            <p class="editContract">{{ $t('contractManagement.editContract') }}</p>
            <div @click="toggleExpand" class="tit-container expandControl">
              <span>{{ expanded ? $t('commonVariables.fold') : $t('commonVariables.unfold') }}</span
              ><i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
          </div>
          <div class="buttonGroup">
            <el-button v-if="authGroup.ContractManagementDownloadContractList" size="mini" class="addButton" @click="exportContractList">
              导出合同列表
            </el-button>
            <el-button v-if="authGroup.ContractManagementCreate" type="primary" size="mini" class="addButton" @click="addContract">
              {{ $t('contractManagement.newContract') }}
            </el-button>
          </div>
        </div>
      </template>
    </filter-pane>
    <div class="searchFormContainer" ref="searchFormContainer">
      <TransitionCollapse @expandOver="expandOver" @retractOver="expandOver" v-show="expanded" :expandHeight="expandHeight">
        <el-form :model="dataForm" ref="dataForm" class="dataForm" label-width="60px" label-position="top">
          <el-form-item prop="contractCodeCont" :label="$t('contractManagement.contractCode')">
            <el-input v-model="dataForm.contractCodeCont" placeholder="请输入合同编号"></el-input>
          </el-form-item>

          <el-form-item prop="projectCodeCont" :label="$t('contractManagement.projectCode')">
            <el-input v-model="dataForm.projectCodeCont" placeholder="请输入项目编号"></el-input>
          </el-form-item>

          <el-form-item prop="customerIdEq" :label="$t('contractManagement.customerName')">
            <el-select size="mini" v-model="dataForm.customerIdEq" filterable clearable>
              <el-option v-for="item in customerOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="bdIdEq" :label="$t('contractManagement.bdNames')">
            <el-select v-model="dataForm.bdIdEq" filterable clearable style="width: 100%">
              <el-option v-for="item in allBd" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="contractTypeEq" :label="$t('contractManagement.contractType')">
            <el-select v-model="dataForm.contractTypeEq" filterable clearable style="width: 100%">
              <el-option v-for="item in contractTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="statusEq" :label="$t('contractManagement.status')">
            <el-select v-model="dataForm.statusEq" filterable clearable style="width: 100%">
              <el-option v-for="item in statusTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item class="dataFormItem" prop="nameCont" :label="$t('contractManagement.projectName')">
            <el-input v-model="dataForm.nameCont" placeholder="请输入项目名称"></el-input>
          </el-form-item> -->

          <el-form-item class="dataFormItem" prop="shortNameCont" :label="$t('contractManagement.projectShortName')">
            <el-input v-model="dataForm.shortNameCont" placeholder="请输入项目简称"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contractManagement.orderDate')">
            <el-date-picker
              v-model="dateVal"
              class="el-date-picker"
              type="daterange"
              range-separator="至"
              :unlink-panels="true"
              :start-placeholder="startPlaceholder"
              :end-placeholder="endPlaceholder"
            ></el-date-picker>
          </el-form-item>
          <el-form-item class="dataFormItem" prop="payMethod" :label="$t('contractManagement.payMethod')">
            <el-select v-model="dataForm.payMethod" placeholder="请选择结算方式" filterable clearable style="width: 100%">
              <el-option v-for="item in payMethodOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="button-group">
            <el-button class="btn" size="mini" type="default" @click="resetForm">{{ $t('commonVariables.reset') }}</el-button>
            <el-button class="btn" size="mini" type="primary" @click="filterData">{{ $t('ResourceManagement.customer.screen') }}</el-button>
          </el-form-item>
        </el-form>
      </TransitionCollapse>
    </div>
    <div class="heat-shield"></div>

    <EditServiceItemTable
      :shrinkage="true"
      @pageChange="pageChange"
      :applyComp="'contractManagement'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      :deleteWarnTips="$t('contractManagement.deleteWarning')"
      :saveKey.sync="saveKey"
      :page.sync="dataForm.page"
      :pagesize.sync="dataForm.pageSize"
      @searchForm="resetData"
      :chooseInputArr="chooseInputArr"
      @editServiceItem="editRow"
      :count="count"
      :authGroup="authGroup"
    ></EditServiceItemTable>

    <el-drawer
      class="record"
      :with-header="false"
      :wrapperClosable="false"
      :show-close="false"
      :modal="true"
      :title="recordForm.id ? $t('contractManagement.editContract') : $t('contractManagement.newContract')"
      :visible.sync="drawerVisable"
      :size="$i18n.locale == 'en' ? 1140 : 1050"
    >
      <div class="drawerHeaderContainer">
        <p>{{ recordForm.id ? $t('contractManagement.editContract') : $t('contractManagement.newContract') }}</p>
        <div class="addContract-button-group">
          <el-button class="btn" size="mini" type="default" @click="closeDrawer($t('contractManagement.closeDrawerTips'))">{{
            $t('commonVariables.cancel')
          }}</el-button>
          <el-button class="btn" size="mini" type="primary" @click="saveAllServiceItems">{{ $t('commonVariables.save') }}</el-button>
        </div>
        <!-- <i class="el-icon-close closeIcon" @click="closeDrawer"></i> -->
      </div>
      <div style="padding: 18px 32px 8px 32px; display: flex; justify-content: start; align-items: center">
        <div style="width: 4px; height: 14px; background: #1890ff; margin-right: 8px"></div>
        <div style="color: #19233b; font-weight: 600; font-size: 16px">基础信息</div>
      </div>
      <el-form ref="recordForm" label-position="right" :label-width="$i18n.locale == 'en' ? '120px' : '90px'" :model="recordForm">
        <div class="recordForm">
          <el-form-item prop="projectCode" :label="$t('contractManagement.projectCode')">
            <el-input v-model="recordForm.projectCode" placeholder="请输入" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="contractCode" :label="$t('contractManagement.contractCode')">
            <el-input v-model="recordForm.contractCode" placeholder="请输入" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="customerId" :label="$t('contractManagement.customerCode')">
            <el-select size="mini" v-model="recordForm.customerId" filterable clearable :disabled="!authGroup.ContractManagementUpdateBasicInfo">
              <el-option v-for="item in customerOptions" :key="item.id" :label="item.code" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="customerName" :label="$t('ResourceManagement.customer.name')">
            <el-input v-model="recordForm.customerName" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="customerShortNameId" :label="$t('contractManagement.customerShortName')">
            <el-select
              v-model="recordForm.customerShortNameId"
              filterable
              clearable
              placeholder="请输入"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            >
              <el-option v-for="item in customerShortNameOptions" :key="item.id" :label="item.shortName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="contractType" :label="$t('contractManagement.contractType')">
            <el-select
              v-model="recordForm.contractType"
              filterable
              clearable
              style="width: 100%"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            >
              <el-option v-for="item in contractTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name" :label="$t('contractManagement.projectName')">
            <el-input v-model="recordForm.name" placeholder="请输入" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="shortName" :label="$t('contractManagement.projectShortName')">
            <el-input v-model="recordForm.shortName" placeholder="请输入" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="period" :label="$t('contractManagement.period')">
            <el-input v-model="recordForm.period" placeholder="请输入" :disabled="!authGroup.ContractManagementUpdateBasicInfo"></el-input>
          </el-form-item>
          <el-form-item prop="currency" :label="$t('contractManagement.currency')">
            <el-select
              v-model="recordForm.currency"
              filterable
              clearable
              style="width: 100%"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            >
              <el-option v-for="item in currencyTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="amount" :label="$t('contractManagement.amount')">
            <el-input
              v-if="authGroup.ContractManagementReadAmout"
              v-model.number="recordForm.amount"
              placeholder="请输入"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            ></el-input>
            <el-input v-else disabled :value="'******'"></el-input>
          </el-form-item>
          <el-form-item prop="year" :label="$t('contractManagement.year')">
            <el-date-picker
              size="mini"
              type="year"
              :value-format="'yyyy'"
              v-model="recordForm.year"
              class="el-date-picker"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="orderDate" :label="$t('contractManagement.orderDate')">
            <el-date-picker
              size="mini"
              type="date"
              v-model="recordForm.orderDate"
              :value-format="'yyyy-MM-dd'"
              class="el-date-picker"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="status" :label="$t('contractManagement.status')">
            <el-select v-model="recordForm.status" filterable clearable style="width: 100%" :disabled="!authGroup.ContractManagementUpdateBasicInfo">
              <el-option v-for="item in statusTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bdIds" :label="$t('contractManagement.bdNames')">
            <!-- <memberSelect :projectUpdate="true" :memberSelected="memberSelected" :allUser="allBd" @changeMemberSelected="changeBdSelected" /> -->
            <el-select
              v-model="recordForm.bdIds"
              collapse-tags
              filterable
              clearable
              multiple
              placeholder="请选择"
              :disabled="!authGroup.ContractManagementUpdateBasicInfo"
            >
              <el-option v-for="item in allBd" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div style="padding: 8px 13px 10px 32px; display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; justify-content: start; align-items: center">
          <div style="width: 4px; height: 14px; background: #1890ff; margin-right: 8px"></div>
          <div style="color: #19233b; font-weight: 600; font-size: 16px">执行部门</div>
        </div>
        <el-button
          :type="recordForm.id ? 'primary' : 'info'"
          @click="refreshDepartments"
          :disabled="!recordForm.id || !authGroup.ContractManagementRefreshDepartment"
        >
          刷新部门
        </el-button>
      </div>
      <div style="padding: 8px 13px 10px 32px">
        <el-table
          :header-cell-style="{ backgroundColor: '#f8f8f9', color: '#515a6e' }"
          :data="recordForm.contractDepartments"
          border
          style="width: 100%"
        >
          <el-table-column prop="project" label="系统项目"></el-table-column>
          <el-table-column prop="department" label="执行部门" width="120"></el-table-column>
          <el-table-column prop="directorName" label="直属总监D" width="120"></el-table-column>
          <el-table-column prop="adName" label="直属副总监AD" width="120"></el-table-column>
          <el-table-column prop="boName" label="客户经理BO" width="120"></el-table-column>
          <el-table-column prop="pmName" label="项目LEAD" width="120"></el-table-column>
        </el-table>
      </div>
      <!-- <div class="addDepartment">
        <div class="addHeaderContainer">
          <p class="left">{{ $t('contractManagement.addDepartment') }}</p>
          <p class="right" @click="addItem"><i class="el-icon-circle-plus-outline addIcon"></i>{{ $t('contractManagement.addDepartment') }}</p>
        </div>
        <div class="addContainer">
          <div class="labelContainer">
            <p>请选择部门</p>
            <p>请选择部门BO</p>
            <p>请选择部门PM</p>
            <div class="a-placeholder"></div>
          </div>
          <div class="mainContainer" v-for="(item, idx) in addDepartmentSums" :key="idx">
            <div class="department">
              <el-select
                @change="chooseDp(addDepartmentSums[idx].group, idx)"
                v-model="addDepartmentSums[idx].group"
                filterable
                :clearable="false"
                style="width: 100%"
              >
                <el-option v-for="item in departmentOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="bo">
              <el-select v-model="addDepartmentSums[idx].boId" filterable clearable style="width: 100%">
                <el-option v-for="val in addDepartmentType[addDepartmentSums[idx].group]" :key="val.id" :label="val.name" :value="val.id">
                </el-option>
              </el-select>
            </div>
            <div class="pm">
              <el-select v-model="addDepartmentSums[idx].pmId" filterable clearable style="width: 100%">
                <el-option v-for="item in addDepartmentType[addDepartmentSums[idx].group]" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="removeOperation">
              <i class="el-icon-remove-outline removeOp" @click="removeDepartment(idx)"></i>
            </div>
          </div>
        </div>
      </div> -->
      <div style="padding: 16px 13px 10px 32px; display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; justify-content: start; align-items: center">
          <div style="width: 4px; height: 14px; background: #1890ff; margin-right: 8px"></div>
          <div style="color: #19233b; font-weight: 600; font-size: 16px">服务节点</div>
        </div>
        <div style="display: flex; flex-direction: row">
          <div>
            <el-button
              v-if="authGroup.ContractManagementUploadServicenode"
              style="margin-right: 8px"
              size="small"
              type="primary"
              @click="downloadTemplate"
            >
              节点模板
            </el-button>
            <el-button v-else style="margin-right: 8px" size="small" disabled>节点模板</el-button>
          </div>
          <el-upload accept=".xlsx" class="upload-excel" action="string" ref="upload" :http-request="uploadFile" :show-file-list="false">
            <el-button v-if="authGroup.ContractManagementUploadServicenode" size="small" type="primary">上传文件</el-button>
            <el-button v-else size="small" disabled>上传文件</el-button>
          </el-upload>
        </div>
      </div>
      <div style="padding: 8px 13px 10px 32px">
        <el-table :header-cell-style="{ backgroundColor: '#f8f8f9', color: '#515a6e' }" :data="recordForm.serviceNodes" border style="width: 100%">
          <el-table-column prop="name" label="服务节点名称" min-width="200" fixed="left">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top" :open-delay="1000">
                <el-input v-model="scope.row.name" :disabled="!authGroup.ContractManagementUpdateServicenodeInfo" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="payPercent" label="结算比例" min-width="130" v-if="authGroup.ContractManagementReadServicenodeSettlement">
            <template slot-scope="scope">
              <el-input v-model="scope.row.payPercent" :disabled="!authGroup.ContractManagementUpdateServicenodeSettlement" />
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="结算金额" min-width="130" v-if="authGroup.ContractManagementReadServicenodeSettlement">
            <template slot-scope="scope">
              <el-input v-model="scope.row.payAmount" :disabled="!authGroup.ContractManagementUpdateServicenodeSettlement" />
            </template>
          </el-table-column>
          <el-table-column prop="department" label="节点部门" min-width="115">
            <template slot-scope="scope">
              <el-select v-model="scope.row.department" :disabled="scope.row.done || !authGroup.ContractManagementUpdateServicenodeInfo">
                <el-option v-for="item in departmentOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="done" label="是否完成节点" min-width="120" v-if="authGroup.ContractManagementReadServicenodeDone">
            <template slot-scope="scope">
              <el-select :value="scope.row.done" @change="val => handleDone(val, scope.row)" :disabled="getDoneDisabled(scope.row)">
                <el-option v-for="item in doneOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="doneDate" label="节点完成时间" min-width="130" v-if="authGroup.ContractManagementReadServicenodeDone">
            <template slot-scope="scope">
              <el-input v-model="scope.row.doneDate" disabled />
            </template>
          </el-table-column>
          <el-table-column prop="billed" label="BD是否出账单" min-width="120" v-if="authGroup.ContractManagementReadServicenodeBilled">
            <template slot-scope="scope">
              <el-select
                :value="scope.row.billed"
                @change="val => handleBilled(val, scope.row)"
                :disabled="!scope.row.done || !authGroup.ContractManagementUpdateServicenodeBilled"
              >
                <el-option v-for="item in doneOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="receivePayment"
            label="财务是否已收款"
            min-width="120"
            v-if="authGroup.ContractManagementReadServicenodeReceivepayment"
          >
            <template slot-scope="scope">
              <el-select
                :value="scope.row.receivePayment"
                @change="val => handleReceivePayment(val, scope.row)"
                :disabled="!(scope.row.done && scope.row.billed) || !authGroup.ContractManagementUpdateServicenodeReceivepayment"
              >
                <el-option v-for="item in doneOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" min-width="60" fixed="right">
            <template slot-scope="scope">
              <el-button
                style="padding-left: 0 !important; padding-right: 0 !important"
                type="text"
                @click="deleteServiceNodes(scope.row)"
                :disabled="!authGroup.ContractManagementUpdateServicenodeInfo"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form class="uploadForm" ref="uploadForm" label-position="right" :label-width="$i18n.locale == 'en' ? '160px' : '80px'">
        <el-form-item :label="$t('contractManagement.preContractUrl')">
          <el-upload
            class="upload-excel"
            action="string"
            ref="preUpload"
            :before-upload="preBeforeUpload"
            :before-remove="preRemove"
            :http-request="preUpload"
            :file-list="preFileList"
          >
            <el-button v-if="authGroup.ContractManagementUploadContractfile" class="btn" size="mini" type="primary">
              {{ $t('contractManagement.uploadPreContract') }}
            </el-button>
            <el-button v-else disabled class="btn" size="mini">
              {{ $t('contractManagement.uploadPreContract') }}
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('contractManagement.finalContractUrl')">
          <el-upload
            class="upload-excel"
            action="string"
            ref="finUpload"
            :before-upload="finBeforeUpload"
            :before-remove="finRemove"
            :http-request="finUpload"
            :file-list="finFileList"
          >
            <el-button v-if="authGroup.ContractManagementUploadContractfile" class="btn" size="mini" type="primary">
              {{ $t('contractManagement.uploadSigningContract') }}
            </el-button>
            <el-button v-else disabled class="btn" size="mini">
              {{ $t('contractManagement.uploadSigningContract') }}
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-dialog title="请选择完成时间" :visible.sync="doneDateDialogVisible" width="20%">
      <el-date-picker style="width: 100%; display: flex" v-model="doneDate" type="date" placeholder="请选择完成时间" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDoneDateDialog">取 消</el-button>
        <el-button type="primary" @click="confirmDoneDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>

  <div v-else>
    <Empty v-if="shouldShowErrimg" class="show_empty" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import resourceManagement from '@/api/modules/resourceManagement.api.js';
import contractManagement from '@/api/modules/contractManagement.api.js';

import TransitionCollapse from '@/components/common/TransitionCollapse.vue';
import Empty from '@/components/Empty/index.vue';
import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';
import getUrlParam from '@/utils/getUrlParam';

// import memberSelect from '@/components/memberSelect/index.vue';
import { dayFormat } from '@/utils/time-tools';

import OSS from 'ali-oss';

import { mixinsResource } from '@/mixins/resourceData';

import responseCheck from '@/utils/saveAs';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    Empty,
    // memberSelect,
    TransitionCollapse,
  },
  name: 'ClientManagement',
  mixins: [mixinsResource],
  data() {
    const validateCode = (rule, value, callback) => {
      if (this.codeError) {
        callback(new Error('编号已被占用'));
      } else if (!value) {
        callback(new Error('请输入编号名称'));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (this.nameError) {
        callback(new Error('名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入方案名称'));
      } else {
        callback();
      }
    };
    return {
      pendingRow: null,
      doneDate: new Date(),
      doneDateDialogVisible: false,
      testData: false,
      repeatCheck: false,
      expanded: true,
      authGroup: {},
      userName: '',
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',
      dateVal: [],
      chooseInputArr: [
        // {
        //   prop: 'contractType',
        // },
        {
          prop: 'contractCode',
        },
        {
          prop: 'projectCode',
        },
        {
          prop: 'projectShortName',
        },
        {
          prop: 'status',
        },
        {
          prop: 'customerName',
        },
        {
          prop: 'payMethod',
        },
      ],
      codeError: false,
      nameError: false,
      dialogRules: {
        code: [{ required: true, type: 'string', validator: validateCode, trigger: 'blur' }],
        name: [{ required: true, type: 'string', validator: validateName, trigger: 'blur' }],
      },
      customer_row: {},
      drawerVisable: false,
      allUser: [],
      allBd: [],
      sponsorOption: [],
      customerOptions: [],
      customerShortNameOptions: [],
      doneOptions: [
        { label: 'Y', value: true },
        { label: 'N', value: false },
      ],
      departmentOptions: [
        { name: 'BD', id: 'BD' },
        { name: 'CP', id: 'CP' },
        { name: 'DBD', id: 'DBD' },
        { name: 'DM', id: 'DM' },
        { name: 'DS', id: 'DS' },
        { name: 'IT', id: 'IT' },
        { name: 'MED', id: 'MED' },
        { name: 'PK', id: 'PK' },
        { name: 'SDTM', id: 'SDTM' },
        { name: 'SP', id: 'SP' },
        { name: 'ST', id: 'ST' },
        { name: '其他', id: '其他' },
        { name: '建库', id: '建库' },
        { name: '研发', id: '研发' },
      ],
      currencyTypeOptions: [
        { name: 'RMB', id: 'rmb' },
        { name: 'USD', id: 'usd' },
        { name: 'HKD', id: 'hkd' },
      ],
      contractTypeOptions: [
        {
          name: 'contractManagement.sales',
          id: 's',
        },
        {
          name: 'contractManagement.purchase',
          id: 'p',
        },
      ],
      payMethodOptions: ['里程碑', '其他', '据实结算'],
      statusTypeOptions: [
        {
          name: 'contractManagement.doingProjectCount',
          id: 'doing',
        },
        {
          name: 'contractManagement.finishProjectCount',
          id: 'finish',
        },
      ],
      serviceTableData: [],
      count: 0,
      showEmpty: false,
      tableLoading: false,
      dataForm: {
        page: 1,
        pageSize: 20,
        contractCodeCont: '',
        projectCodeCont: '',
        customerIdEq: '',
        contractTypeEq: '',
        statusEq: '',
        bdIdEq: '',
        nameCont: '',
        shortNameCont: '',
        payMethod: '',
      },
      memberSelected: [],
      showMemberSelectedName: [],
      saveKey: false,
      recordData: [],
      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
      // 抽屉
      recordForm: {
        id: '',
        projectCode: '',
        contractCode: '',
        customerId: '',
        contractType: '',
        name: '',
        shortName: '',
        currency: '',
        amount: 0,
        year: '',
        orderDate: '',
        status: '',
        contractStaffs: [],
        bdIds: [],
        preContractUrls: [],
        finalContractUrls: [],
        period: '',
        customerName: '',
        contractDepartments: [],
        serviceNodes: [],
      },
      projectCodeCopy: '',
      contractCodeCopy: '',
      // addDepartmentSums: [],
      // addDepartmentType: { pk: [], med: [], pv: [], co: [], stat: [], dm: [] },
      preFileList: [],
      finFileList: [],
    };
  },

  async created() {
    await this.getSearchByUserName();
    await this.resetData();
    this.userName = JSON.parse(JSON.stringify(this.$store.state.user.name));
    this.authGroup = JSON.parse(JSON.stringify(this.$store.state.app.userConfig.permissionsKeys));
    this.dataForm.contractCodeCont = getUrlParam()?.contractCode ? getUrlParam().contractCode : '';
    this.filterData();
  },

  methods: {
    getDoneDisabled(row) {
      // 若权限为 false，直接返回 true，禁用 select
      if (!this.authGroup.ContractManagementUpdateServicenodeDone) {
        return true;
      }
      // 部分数据中可能存在部门名称为小写的情况，这里统一转大写
      const department = row.department.toUpperCase();
      // 若节点部门为 BD，判断当前合同的 BD 有没有包含当前用户，没有则禁用 select
      if (department === 'BD') {
        return !this.recordForm.bdNames.includes(this.userName);
      }
      // 若节点部门不是 BD
      const contractDepartmentItem = this.recordForm.contractDepartments?.filter(item => item.department === department);
      // 找不到对应的执行部门记录，禁用 select
      if (!contractDepartmentItem.length) {
        return true;
      }
      const members = Array.from(new Set(contractDepartmentItem.flatMap(item => [item.adName, item.boName, item.directorName, item.pmName])));
      // 对应的执行部门记录中有没有包含当前用户，没有则禁用 select
      return !members.includes(this.userName);
    },
    expandOver() {
      this.$nextTick(() => {
        this.calcTreeDom();
      });
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    preRemove(file) {
      this.uploadRemove(file, 'preUpload');
    },
    finRemove(file) {
      this.uploadRemove(file, 'finUpload');
    },
    uploadRemove(file, name) {
      return this.$confirm(this.$t('contractManagement.sureDeleteFileTips') + `${file.name}？`, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        if (name === 'preUpload') {
          const delIDx = this.preFileList.findIndex(item => {
            return item.name === file.name;
          });
          this.preFileList.splice(delIDx, 1);
          this.recordForm.preContractUrls.splice(delIDx, 1);
        } else {
          const delIDx = this.finFileList.findIndex(item => {
            return item.name === file.name;
          });
          this.finFileList.splice(delIDx, 1);
          this.recordForm.finalContractUrls.splice(delIDx, 1);
        }
      });
    },
    preBeforeUpload(file) {
      this.existCheckFn(file, 'preUpload');
    },
    finBeforeUpload(file) {
      this.existCheckFn(file, 'finUpload');
    },
    existCheckFn(file, refName /* result */) {
      let existKey = -1;
      if (refName === 'preUpload') {
        existKey = this.preFileList.findIndex(val => val.name === file.name);
      } else {
        existKey = this.finFileList.findIndex(val => val.name === file.name);
      }
      if (~existKey) {
        this.$refs[refName].uploadFiles.splice(
          this.$refs[refName].uploadFiles.findLastIndex(val => val.name === file.name),
          1
        );
        this.$message.error('请不要重复添加相同的文件');
        this.repeatCheck = true;
        return false;
      } else {
        refName === 'preUpload' ? this.preFileList.push(file) : this.finFileList.push(file);
      }
    },
    finUpload(param) {
      this.manualUpload(param, 'finUpload');
    },
    preUpload(param) {
      this.manualUpload(param, 'preUpload');
    },
    manualUpload(param, name) {
      this.$nextTick(() => {
        contractManagement.uploadContract().then(async res => {
          const sts = Object.assign({}, res[1]);
          const client = new OSS({
            accessKeyId: sts.accessId,
            accessKeySecret: sts.accessKeySecret,
            bucket: sts.bucket,
            region: sts.region,
            stsToken: sts.securityToken,
          });
          const result = await client.multipartUpload(sts.dir + param.file.name, param.file, {
            // progress: progress
          });
          if (!this.repeatCheck) {
            name === 'preUpload'
              ? this.recordForm.preContractUrls.push(result.res.requestUrls[0])
              : this.recordForm.finalContractUrls.push(result.res.requestUrls[0]);
          }
          this.repeatCheck = false;
        });
      });
    },

    // 上传合同
    resetForm() {
      this.$refs['dataForm'].resetFields();
      this.memberSelected = [];
      this.showMemberSelectedName = [];
      this.dateVal = [];
    },
    addContract() {
      // this.addItem();
      this.drawerVisable = true;
    },
    // addItem() {
    //   this.addDepartmentSums.push({
    //     group: '',
    //     boId: '',
    //     pmId: '',
    //   });
    // },
    closeDrawer(warnTips) {
      this.$confirm(warnTips, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        this.cancelSave();
      });
    },
    cancelSave() {
      this.showMemberSelectedName = [];
      this.memberSelected = [];
      // this.addDepartmentSums = [];
      this.preFileList = [];
      this.finFileList = [];
      this.recordForm = {
        id: '',
        projectCode: '',
        contractCode: '',
        customerId: '',
        contractType: '',
        name: '',
        shortName: '',
        currency: '',
        amount: 0,
        year: '',
        orderDate: '',
        status: '',
        contractStaffs: [],
        bdIds: [],
        preContractUrls: [],
        finalContractUrls: [],
        period: '',
        customerName: '',
        contractDepartments: [],
        serviceNodes: [],
      };
      this.projectCodeCopy = '';
      this.contractCodeCopy = '';
      this.$refs.preUpload.clearFiles();
      this.$refs.finUpload.clearFiles();
      this.drawerVisable = false;
    },
    //按名字检索用户
    async getSearchByUserName() {
      let [bdData] = await contractManagement.getuserOption({ groupCodeEq: 'BD' });
      let [sponsorData] = await resourceManagement.sponsorData({ page: 1, pagesize: 999999 });
      let [customerData] = await resourceManagement.customersData({ page: 1, pagesize: 999999 });
      this.sponsorOption = [...sponsorData.sponsors];
      if (bdData) {
        this.allBd = [...bdData.users];
      }
      if (customerData) {
        this.customerOptions = [...customerData.customers];
        this.customerShortNameOptions = this.customerOptions
          .filter(item => item.shortName)
          .map(item => {
            return {
              id: item.id,
              shortName: item.shortName,
            };
          });
      }
    },
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pageSize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    // setCustomerName(item) {
    //   this.recordForm.customerName = this.customerOptions.find(val => val.id == item).name;
    // },
    translateUrl(url) {
      let finishUrl = new URL(url);
      finishUrl.hash = '';
      finishUrl.search = '';
      finishUrl = finishUrl.toString();
      const encodedUrl = decodeURI(finishUrl);
      return encodedUrl;
    },
    async editRow(row) {
      this.recordForm = JSON.parse(JSON.stringify(row)); // deepClone
      this.projectCodeCopy = JSON.parse(JSON.stringify(row.projectCode));
      this.contractCodeCopy = JSON.parse(JSON.stringify(row.contractCode));
      // this.addDepartmentSums = [...row.contractStaffs];
      // await this.addDepartmentSums.map(async item => {
      //   if (this.addDepartmentType[item.group] && this.addDepartmentType[item.group].length) {
      //     return;
      //   }
      //   let [options] = await contractManagement.getuserOption({ groupCodeEq: item.group });
      //   this.addDepartmentType[item.group] = [...options.users];
      // });
      this.memberSelected = [...row.bdIds];
      this.showMemberSelectedName = [...row.bdNames];

      if (row.preContractUrls.length) {
        row.preContractUrls.filter(val => {
          let finUrl = this.translateUrl(val);
          this.preFileList.push({ name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl });
        });
      }
      if (row.finalContractUrls.length) {
        row.finalContractUrls.filter(val => {
          let finUrl = this.translateUrl(val);
          this.finFileList.push({ name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl });
        });
      }

      this.drawerVisable = true;
    },
    // changeBdSelected(vals, ids) {
    //   this.showMemberSelectedName = [...vals];
    //   this.recordForm.bdIds = [...ids];
    //   this.memberSelected = [...ids];
    // },
    async deleteData(id) {
      try {
        await contractManagement.delContract(id);
        this.resetData();
      } catch (err) {
        this.$message.error('保存项目失败！');
        return;
      }
    },
    filterData() {
      this.dataForm.page = 1;
      this.dataForm.pageSize = 20;
      this.count = 0;
      this.refreshData();
    },
    async refreshData() {
      if (!this.authGroup.ContractManagementRead) {
        this.errImg = require('@/assets/noquanxian.png');
        this.errInfo = '抱歉，您暂无权限哦～';
        this.shouldShowErrimg = true;
        this.$message.closeAll();
        return;
      }
      this.tableLoading = true;
      let q = Object.assign({}, this.dataForm);
      q.orderDateGte = this.dateVal?.[0] ? dayFormat(this.dateVal[0], 'YYYY-MM-DD') : '';
      q.orderDateLte = this.dateVal?.[1] ? dayFormat(this.dateVal[1], 'YYYY-MM-DD') : '';
      contractManagement.getContractList(q).then(res => {
        if (res[2].status !== 200) {
          return;
        }
        if (!res[0].contracts || !res[0].contracts.length > 0) {
          this.serviceTableData = [];
          this.tableLoading = false;
          this.showEmpty = true;
          return;
        }
        const result = res[0].contracts.map(item => {
          return {
            ...item,
            projectShortName: item.shortName,
          };
        });
        /*           this.countProjSummary = result.length; */
        this.serviceTableData = [...result];

        this.tableLoading = false;
        this.showEmpty = false;
        this.count = res[0].count ? res[0].count : 0;
      });
    },
    //保存服务项
    async saveAllServiceItems() {
      this.$refs.recordForm.validate(async valid => {
        if (!valid) {
          return;
        }
        // let isFillDepartment = false;
        // this.addDepartmentSums.map((i, idx) => {
        //   for (let i in this.addDepartmentSums[idx]) {
        //     if (!this.addDepartmentSums[idx][i]) {
        //       if (i == 'group') isFillDepartment = true;
        //       this.addDepartmentSums[idx][i] = null;
        //     }
        //   }
        // });
        // if (isFillDepartment) {
        //   this.$message.error('添加部门一栏中，部门选项必填！');
        //   return;
        // }
        // this.recordForm.customerName = ''; // ?
        const submitForm = Object.assign({}, this.recordForm);
        submitForm.bdNames = null;
        submitForm.serviceNodes = submitForm.serviceNodes.map(i => {
          return {
            ...i,
            // doneDate: i.doneDate ? i.doneDate.replaceAll('/', '-') : '',
            payAmount: i.payAmount + '',
            clientId: undefined, // 前端 ID 无需带给后端
          };
        });

        // submitForm.contractStaffs = [...this.addDepartmentSums];
        const editId = submitForm?.id;
        let res = null;
        if (editId) {
          try {
            if (
              (submitForm.projectCode !== this.projectCodeCopy || submitForm.contractCode !== this.contractCodeCopy) &&
              submitForm.contractDepartments?.length > 0
            ) {
              await this.$confirm('当前已有执行部门，修改后将重置，请确认是否继续', '', {
                confirmButtonText: '继续',
                cancelButtonText: '取消',
                type: 'warning',
              });
            }
            const valid = await contractManagement.validateContractCodeUniq({
              id: editId,
              contractCode: submitForm.contractCode,
            });
            if (!valid[1].valid) {
              this.$message.error('当前合同编号已存在，请重新输入');
              return;
            }
            res = await contractManagement.updateContract(submitForm, editId);
          } catch (error) {
            return;
          }
        } else {
          const valid = await contractManagement.validateContractCodeUniq({
            contractCode: submitForm.contractCode,
          });
          if (!valid[1].valid) {
            this.$message.error('当前合同编号已存在，请重新输入');
            return;
          }
          res = await contractManagement.createContract(submitForm);
        }
        this.dialogLoading = false;
        this.confirmBtnText = '确定';

        if (!res[1].code && res[2].status !== 404) {
          this.cancelSave();
          await this.refreshData();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
        } else if (res[1].metadata) {
          const errData = res[1].metadata;
          errData.code ? (this.codeError = true) : null;
          errData.name ? (this.nameError = true) : null;
        }
      });
    },
    // removeDepartment(idx) {
    //   this.addDepartmentSums.splice(idx, 1);
    // },
    resetData() {
      this.dataForm = {
        page: 1,
        pageSize: 20,
        contractCodeCont: '',
        projectCodeCont: '',
        customerIdEq: '',
        contractTypeEq: '',
        statusEq: '',
        bdIdEq: '',
        nameCont: '',
        shortNameCont: '',
        payMethod: '',
      };

      this.dateVal = [];

      this.count = 0;
      this.refreshData();
    },
    // async chooseDp(item, idx) {
    //   this.addDepartmentSums[idx].boId = null;
    //   this.addDepartmentSums[idx].pmId = null;
    //   if (this.addDepartmentType[item].length) {
    //     return;
    //   }
    //   let [options] = await contractManagement.getuserOption({ groupCodeEq: item });
    //   this.addDepartmentType[item] = [...options.users];
    // },
    handleSizeChange(val) {
      this.dataForm.pageSize = val;
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.dataForm.page = val;
      this.refreshData();
    },
    handleDone(val, row) {
      if (val) {
        this.doneDateDialogVisible = true;
        this.pendingRow = row;
        return;
      }
      if (!row.billed) {
        row.done = val;
        return;
      }
      this.$message.error('请先将"BD是否出账单"改为"N"');
    },
    handleBilled(val, row) {
      if (val) {
        row.billed = val;
        return;
      }
      if (!row.receivePayment) {
        row.billed = val;
        return;
      }
      this.$message.error('请先将"财务是否已收款"改为"N"');
    },
    handleReceivePayment(val, row) {
      row.receivePayment = val;
    },
    closeDoneDateDialog() {
      this.doneDate = new Date();
      this.doneDateDialogVisible = false;
    },
    confirmDoneDate() {
      if (!this.doneDate) {
        this.$message.error('请选择日期');
        return;
      }
      this.pendingRow.done = true;
      this.pendingRow.doneDate = this.doneDate.toLocaleString().split(' ')[0].replaceAll('/', '-');
      this.closeDoneDateDialog();
    },
    async refreshDepartments() {
      const res = await contractManagement.getCurrentContract(this.recordForm.id);
      if (res[2].status === 200) {
        this.recordForm.contractDepartments = res[0].contractDepartments;
        this.$message.success('刷新部门成功');
      }
    },
    deleteServiceNodes(row) {
      const index = this.recordForm.serviceNodes.findIndex(item => item.id == row.id || item.clientId == row.clientId);
      this.recordForm.serviceNodes.splice(index, 1);
      this.$message.success('删除成功，请点击保存进行确认');
    },
    async exportContractList() {
      const res = await contractManagement.exportContractList();
      if (res[2].status === 200) {
        responseCheck(res[2]);
        this.$message.success('导出成功');
        return;
      }
      this.$message.error('导出失败');
    },
    async uploadFile(params) {
      if (params.file.name.split('.')[1] !== 'xlsx') {
        this.$message.error('请上传 xlsx 文件');
        return;
      }
      const reader = new FileReader();
      reader.onload = async e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        if (workbook.SheetNames.length > 1) {
          this.$message.error('请上传包含单个 sheet 的 xlsx 文件');
          return;
        }
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        if (jsonData.length === 0) {
          this.$message.error('请不要上传空文件');
          return;
        }
        if (Object.keys(jsonData[0]).toString() !== ['name', 'payPercent', 'payAmount', 'department'].toString()) {
          this.$message.error('请确保 xlsx 文件中的表头为 name, payPercent, payAmount, department');
          return;
        }
        // 20241225: 允许上传的服务节点拥有相同的 name
        // if (jsonData.map(item => item.name).length !== new Set(jsonData.map(item => item.name)).size) {
        //   this.$message.error('请确保 xlsx 文件中的 name 列没有重复值');
        //   return;
        // }
        const jsonDataDepartment = Array.from(new Set(jsonData.filter(item => item.department).map(item => item.department)));
        if (jsonDataDepartment.some(item => this.departmentOptions.map(item => item.name).indexOf(item) === -1)) {
          this.$message.error('请确保 xlsx 文件中的 department 列的值是合法的');
          return;
        }
        // 20241225: 允许上传的服务节点与当前存在的服务节点拥有相同的 name
        // const recordFormNames = this.recordForm.serviceNodes.map(item => item.name);
        // if (jsonData.map(item => item.name).some(item => recordFormNames.indexOf(item) !== -1)) {
        //   this.$message.error('请确保 xlsx 文件中的 name 列与当前列表中的服务节点名称列没有重复值');
        //   return;
        // }
        this.recordForm.serviceNodes = [
          ...this.recordForm.serviceNodes,
          ...jsonData.map(item => {
            return {
              ...item,
              payPercent: typeof item.payPercent === 'number' ? (item.payPercent * 100).toFixed(2) + '%' : item.payPercent,
              done: false,
              billed: false,
              receivePayment: false,
              clientId: this.generateUUID(), // 对应新数据，生成前端 ID
            };
          }),
        ];
        this.$message.success('上传成功');
      };
      reader.readAsBinaryString(params.file);
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    downloadTemplate() {
      const headers = ['name', 'payPercent', 'payAmount', 'department'];
      const data = [];
      const worksheetData = [headers, ...data];
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Template');
      XLSX.writeFile(wb, 'Template.xlsx');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
#contractManagement {
  @include resourceStyle;
  padding: 14px 0;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 0 16px;
  margin-right: 24px;
  margin-top: 0;
  overflow-y: hidden;
  ::v-deep .top-box {
    display: flex;
    border-bottom: 1px solid #e8eaec;
    min-height: 56px;
    align-items: center;
  }
  .heat-shield {
    background: #f8f8f8f9;
    height: 16px;
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    line-height: 32px;
    font-weight: normal;
    color: #515a6e;
  }
  ::v-deep .el-input__prefix {
    top: -3px;
  }
  .headerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: auto;
    .top-header-tit {
      font-size: 16px;
      font-weight: 600;
      color: #19233b;

      .expandControl {
        display: inline-block;
        margin-left: 12px !important;
        color: #409eff;
        cursor: pointer;
        font-size: 14px;
        span {
          padding-right: 5px;
        }
      }
    }

    .editContract {
      display: inline-block;
      margin: 0;
      padding-left: 32px;
      font-size: 16px;
      font-weight: 700;
      color: #19233b;
    }
    .buttonGroup {
      margin-right: 32px;
    }
  }

  .searchFormContainer {
    background: #ffffff;
    border-radius: 2px;
    .dataForm {
      padding: 0 32px;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 14px;
      .el-form-item {
        margin: 0;
        flex: 0;
        margin-right: 48px;
        min-width: 260px;

        padding: 10px 0;
        ::v-deep .el-select {
          width: 100%;
        }
        ::v-deep .el-form-item__content {
          line-height: 32px;
        }
        ::v-deep .el-input__inner {
          color: #515a6e;
          height: 32px;
          line-height: 32px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          font-size: 14px;
        }
        ::v-deep .el-form-item__label {
          color: #515a6e;
          font-weight: normal;
          font-size: 14px;
          height: 20px;
          padding: 0;
          line-height: 20px;
          margin-top: 0;
          margin-bottom: 7px;
        }

        .el-date-picker {
          height: 32px;
          line-height: 32px;
        }
      }

      .button-group {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding-right: 0;

        .el-button {
          height: 32px;
          padding: 6px 20px;
        }
      }
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
  }

  .record {
    ::v-deep .drawerHeaderContainer {
      border-bottom: 1px solid #e8eaec;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-right: 13px;
      padding-left: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 19px;
        font-weight: 700;
        color: #19233b;
      }
      .addContract-button-group {
        .el-button {
          width: 70px;
          height: 32px;
        }
      }
    }
    .closeIcon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 18px;
    }
    .recordForm {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: px;
      // padding-top: 14px;
      padding-left: 13px;
      padding-right: 13px;
      ::v-deep .el-input__inner {
        height: 32px;
        width: 248px;
      }

      .el-form-item {
        margin: 0;
        padding: 10px 0;
        text-align: left;
      }
    }

    .addDepartment {
      margin: 0 12px;
      margin-left: 28px;
      .addHeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 600;
        }
        .right {
          cursor: pointer;
          .addIcon {
            color: #1890ff;
            margin-right: 4px;
            font-size: 22px;
            line-height: 28px;
            vertical-align: middle;
          }
        }
      }
      .addContainer {
        overflow: hidden;
        margin: 0 auto;
        width: 100%;
        max-height: 260px;
        background: #f8f8f9;
        border-radius: 4px;
        overflow-y: auto;
        .labelContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 10px 0;
          p {
            width: 224px;
            margin: 0 !important;
          }
          .a-placeholder {
            width: 16px;
          }
        }
        .mainContainer {
          padding: 8px 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          .department,
          .bo,
          .pm {
            width: 210px;
          }
          .removeOp {
            cursor: pointer;
            color: #1890ff;
            font-size: 22px;
            margin-right: 20px;
          }
        }
      }
    }
    .uploadForm {
      ::v-deep .el-upload-list__item {
        transition: none !important;
      }
      .el-form-item {
        padding: 14px 0;
      }
    }
  }
}
/* @media screen and (min-width: 900px) and (max-width: 1550px) {
  #contractManagement {
    @include resourceStyle;
    padding: 0 !important;
    padding-bottom: 0;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0;
    overflow-y: hidden;
  }
} */
::v-deep .el-dialog__body {
  padding: 10px 20px;

  .el-icon-date {
    transform: translateY(3px);
  }
}
::v-deep .el-table__empty-block {
  height: 300px !important;
}
</style>
